<template>
  <div class="show has-nav">
    <div class="search-box">
      <t-search
        type="text"
        v-model="searchKey"
        placeholder="请输入关键字搜索"
        placeholder-class="input-font"
        @submit="toSearch"
        shape="round"
      />
      <!-- <div class="search flex-row">
        <img src="@/assets/images/icon-search.svg" class="icon-search" />
       
        <img
          src="@/assets/images/icon-clear.svg"
          class="search-clear"
          @click="clearSearch"
        />
      </div> -->
    </div>
    <div class="year-tab">
      <div
        class="year-item"
        :class="curYear == 2024 ? 'active' : ''"
        @click="changeYear(2024)"
      >
        2024
      </div>
      <div
        class="year-item"
        :class="curYear == 2023 ? 'active' : ''"
        @click="changeYear(2023)"
      >
        2023
      </div>
      <div
        class="year-item"
        :class="curYear == 2022 ? 'active' : ''"
        @click="changeYear(2022)"
      >
        2022
      </div>
    </div>
    <div class="container">
      <div class="top">
        <div class="tabs">
          <div
            class="tab-item"
            :class="curCategory.category_id == item.category_id ? 'active' : ''"
            v-for="item in categoryList"
            :key="item.category_id"
            @click="toChangeCategory(item)"
          >
            <text>{{ item.short_name }}</text>
          </div>
        </div>
        <div
          class="segment"
          v-if="curCategory && curCategory.children.length > 0"
        >
          <a
            v-for="item in curCategory.children"
            :key="item.category_id"
            :class="currentTag.short_name == item.short_name ? 'active' : ''"
            @click="changeTag(item)"
            >{{ item.short_name }}</a
          >
        </div>
      </div>
      <div class="works-list">
        <!-- <div class="panel" v-if="worksList.length == 0">
          <div class="none">
            <img src="@/assets/images/none.png" />
            敬请期待
          </div>
        </div> -->
        <div v-for="item in worksList" :key="item.vote_id">
          <div
            @click="$router.push('/workdetail/' + item.vote_id)"
            class="works-view"
            v-if="item.is_only_show == '0'"
          >
            <div class="works-id">
              {{ item.is_only_show == "0" ? item.vote_code : "" }}
            </div>
            <img class="work-cover" mode="aspectFill" :src="item.image" />
            <span
              :class="
                item.is_only_show == '1' ? 'onlyShow-title' : 'works-title'
              "
            >
              {{ item.title }}
            </span>
            <div class="works-info" v-if="false">
              <div class="works-score">
                {{ item.total_score }} <span>赞</span>
              </div>
              <t-button
                class="works-fav"
                theme="primary"
                shape="round"
                size="small"
                @click.stop="toFav(item)"
              >
                <icon name="thumb-up-2" /> 点赞
              </t-button>
            </div>
          </div>
          <div v-else>
            <div
              class="onlyShow"
              @click="$router.push('/workdetail/' + item.vote_id)"
            >
              <div class="headline">
                <img src="@/assets/images/shape.png" class="shape" />
                <div class="txt">
                  <span>{{ curYear }}年度河北省巾帼志愿服务</span>
                  <span>“五个十”先进典型</span>
                </div>
              </div>
              <img class="work-cover" mode="aspectFill" :src="item.image" />
              <span :class="item.is_only_show == '1' ? 'onlyShow-title' : ''">{{
                item.title
              }}</span>
            </div>
            <div class="tip-txt">*此案例不参与本次社会评价</div>
          </div>
        </div>
      </div>
    </div>
    <div class="totop" @click="toTop">
      <img src="@/assets/images/top.svg" />
    </div>
    <div class="tips">
      <img src="@/assets/images/icon-tip.svg" class="icon-tip" /><span
        >每个微信用户每天可点赞10次</span
      >
    </div>
    <!-- <dialog
      id="login-dialog"
      title=""
      content="本操作需要获取您的手机号码"
      confirmText="确认"
      bind:onGetPhone="onGetPhone"
    /> -->
  </div>
</template>

<script setup>
import { Icon } from "tdesign-icons-vue-next";
import { onMounted, ref } from "vue";
import { globals } from "../main";
import { getCategoryList, getWorksList } from "../network/API";
import tools from "../utils/tools";
const categoryList = ref([]);
const worksList = ref([]);
const curCategory = ref(null);
const currentTag = ref(null);
const searchKey = ref("");
const lastTap = null;
const curYear = ref("2024");
var _noMoreData = false;
var _isLoadingMore = false;
var pageNumber = 1;
var emptyData = false;
// mounted
onMounted(() => {
  getCategory();
  tools.shareInit();
});
function toSearch() {
  pageNumber = 1;
  getWorkList();
}
function clearSearch() {
  pageNumber = 1;
  searchKey.value = "";
  getWorkList();
  window.scrollTo({
    top: 0,
  });
}
async function getCategory() {
  let list = await getCategoryList(curYear.value);
  categoryList.value = list;
  if (list.length > 0) {
    curCategory.value = list[0];
    getWorkList();
  } else {
    worksList.value = [];
  }
}
async function getWorkList() {
  let list = await getWorksList({
    year: curYear.value,
    page_size: 1000,
    page: pageNumber,
    category_id: currentTag.value
      ? currentTag.value.category_id
      : curCategory.value.category_id,
    keyword: searchKey.value,
  });
  worksList.value = list;
}
function changeYear(year) {
  if (year == curYear.value) {
    return;
  }
  curYear.value = year;
  pageNumber = 1;
  getCategory();
}
function toChangeCategory(item) {
  curCategory.value = item;
  if (item.children.length > 0) {
    currentTag.value = item.children[0];
  } else {
    currentTag.value = null;
  }
  toSearch();
}
function changeTag(item) {
  currentTag.value = item;
  toSearch();
}
function toTop() {
  window.scrollTo({
    top: 0,
  });
}
async function toFav(item) {
  globals.$showLoading();
  let res = await doVote({
    id: item.vote_id,
  });
  Toast.clear();
}
</script>

<style scoped>
.container {
  padding: 0 15px 15px 15px;
}
.search-box {
  background: none;
  padding: 15px 15px;
  background: #fff;
}

.search-box .search {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  height: 35px;
  line-height: 35px;
  border-radius: 35px;
}

.search-box .icon-search {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-right: 10px;
}

.search-box .search .t-input {
  font-size: 15px;
  color: #333;
  flex: 1;
  padding: 0;
  padding-right: 25px;
  background: none;
  border: none;
}
.t-input--border::after {
  display: none;
}
.search-box .input-font {
  color: #bbb;
}

.search-box .search-clear {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 5px;
  padding: 9px;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
}

.search-box .icon-search {
  width: 16px;
  height: 16px;
  margin-left: 15px;
  margin-right: 10px;
}
.top {
  padding: 12px 0;
}

.onlyShow {
  color: #f7842c;
  font-size: 12px;
}

.panel {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 25px 15px;
}

.onlyShow {
  border-radius: 15px;
  background: linear-gradient(90deg, #ff7300 0%, #ee2e1b 100%);
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 100%); */
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.2);
}

.onlyShow-title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin: 10px 0 0 0;
}
.work-cover {
  border-radius: 10px;
  height: 178px;
  object-fit: cover;
}
.headline {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.shape {
  width: 127px;
  position: absolute;
  right: -30px;
}
.tip-txt {
  color: #f7842c;
  font-size: 12px;
  padding-left: 15px;
  margin-bottom: 20px;
}
.headline .txt {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  display: inline-flex;
  flex-direction: column;
  padding-left: 5px;
}

.icon-headline {
  width: 32px;
  height: 32px;
}

.tips {
  position: fixed;
  bottom: calc(56px + env(safe-area-inset-bottom));
  left: 0;
  height: 35px;
  background: #ffffcc;
  width: 100%;
  font-size: 12px;
  color: #f44336;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.icon-tip {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.year-tab {
  display: flex;
  align-items: center;
  color: #f7842c;
  background: #fff;
}
.year-item {
  padding: 7px 15px;
  margin-right: 5px;
  border-bottom: 3px solid #fff;
}
.year-item.active {
  font-weight: 700;
  color: #f7842c;
  border-bottom: 3px solid #f7842c;
}
.totop {
  position: fixed;
  bottom: 80px;
  right: 10px;
  width: 40px;
  height: 40px;
  z-index: 10;
  border-radius: 50%;
  background: #fff;
}
.totop img {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;

  z-index: 2;
}
.works-view {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  border-radius: 15px;
  padding: 15px;
}
.works-id {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}
.works-title {
  padding: 15px 0;
  font-size: 16px;
  font-weight: 600;
}
.works-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.works-score {
  font-size: 20px;
}
.works-score span {
  font-size: 15px;
  color: #999;
}
.works-fav {
}
</style>
